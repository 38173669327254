import Dropdown from "./modules/Dropdown";
import Tooltip from "./modules/Tooltip";

Dropdown();
Tooltip();

const goTop = document.querySelector(".go-top__btn");
if (goTop) {
  goTop.addEventListener("click", () => {
    window.scrollTo(0, 0);
  });
}

const searchModal = document.querySelector("#search-tm-1-modal");
const searchOpenBtn = document.querySelector("#module-tm-1");
const searchCloseBtn = document.querySelector("#search-tm-1-modal .uk-close");

if (searchModal && searchCloseBtn && searchOpenBtn) {
  searchCloseBtn.addEventListener("click", () => {
    searchModal.classList.remove("uk-open");
  });
  searchOpenBtn.addEventListener("click", () => {
    searchModal.classList.add("uk-open");
  });
}

const navExpandBtn = document.querySelector(".uk-panel .uk-parent a");
if (navExpandBtn) {
  navExpandBtn.addEventListener("click", (e: Event) => {
    e.preventDefault();
    navExpandBtn.parentElement?.classList.toggle("uk-open");
    if (navExpandBtn.parentElement?.classList.contains("uk-open")) {
      navExpandBtn.nextElementSibling?.removeAttribute("hidden");
    } else {
      navExpandBtn.nextElementSibling?.setAttribute("hidden", "hidden");
    }
  });
}

const mobileNav = document.querySelector("#tm-mobile");
const mobileNavBtn = document.querySelector(".uk-navbar-toggle");

if (mobileNav && mobileNavBtn) {
  mobileNavBtn.addEventListener("click", () => {
    mobileNav.classList.toggle("uk-open");
  });
}
